
:root {
    --body-font-size: 1.6rem;

    // headings
    --h1-font-size: 2.5rem;
    --h1-line-height: inherit;
    // --h1-line-height: 1.2;

    --h2-font-size: 2rem;
    --h2-line-height: inherit;
    // --h2-line-height: 1.5;

    --h3-font-size: 1.8rem;
    --h3-line-height: inherit;

    --h4-font-size: 1.7rem;
    --h4-line-height: inherit;
    // --h4-line-height: 1.5;

    --h5-font-size: 1.6rem;
    --h5-line-height: inherit;

    --h6-font-size: 1.6rem;
    --h6-line-height: inherit;

    /* [02] */
    // big
    --giga-font-size: 8rem;
    --mega-font-size: 6.4rem;
    --kilo-font-size: 5.6rem;

    // small
    --milli-font-size: 1.2rem;
    --centi-font-size: 1.4rem;
}

@include screen($bp600) {
    :root {
        --h1-font-size: 3rem;
        --h2-font-size: 2.2rem;
        --h3-font-size: 2.1rem;
        --h4-font-size: 1.8rem;
        --h5-font-size: 1.7rem;
    }
}

@include screen($bp992) {
    :root {
        --h1-font-size: 3.3rem;
        --h2-font-size: 2.7rem;
    }
}

@include screen($bp1300) {
    :root {
        --h1-font-size: 3.7rem;
        --h2-font-size: 3.2rem;

        --h1-line-height: 1.3;
        --h2-line-height: 1.3;
        --h3-line-height: 1.3;
        --h4-line-height: 1.3;
        --h5-line-height: 1.3;
        --h6-line-height: 1.3;

    }
}


/*
Basic system font stacks
*/

$primary-font: 'Lato', -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;

$accent-font:  'Roboto Slab', Iowan Old Style, Apple Garamond, Baskerville, Times New Roman, Droid Serif, Times, Source Serif Pro, serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;


/* Weights */

$fw-normal: 400;

$fw-bold: 800;


/* Size */

$fs-small: var(--milli-font-size);

$fs-body: var(--body-font-size);


// Color

$color-body-font: $color-secondary;



/* -----
* NOTES
* [02]
* A series of classes for setting massive type; for use in heroes, mastheads,
* promos, etc.
* As per: csswizardry.com/2012/02/pragmatic-practical-font-sizing-in-css
------ */
