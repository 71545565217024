

@import '06-abstracts/_colors';

@import '06-abstracts/_breakpoint-mixins';

@import '06-abstracts/_fonts';

@import '06-abstracts/_animation';

/*
 * frankenMenu v1.0 // Karmabunny Web Design // built by Luke Underwood
 */


/** Desktop and Mobile styling
===================================*/
#frankenmenu-list,
#frankenmenu-list ul {
	list-style: none;
	padding: 0;
	margin: 0;
	display: block;
}

#frankenmenu-list a {
	text-decoration: none;
	display: block;
	font-size: 1.6rem;
}


/** Desktop menu only
===================================*/
@include screen($bp769) {

	#frankenmenu {
		z-index: 99999;
	}

	#frankenmenu-list {
		text-align: left;
		float: right;
		margin-top: 28px;
	}
	#mobile-header {
		display: none;
	}

	/* ---- All level menu items ---- */
	#frankenmenu-list .menu-item {
		position: relative;
	}
	#frankenmenu-list .sub-menu {
		display: none;
		min-width: 200px;
		position: absolute;
		box-shadow: 0 0 16px 1px rgba(0,0,0,0.4);
		z-index: 1;
		min-width: 250px;
		max-width: 350px;
		text-align: left;
		top: 0;
		left: 100%;
	}

	/* ---- First level menu ---- */
	#frankenmenu-list .menu-item-depth1 {
		float: left;
		text-align: center;
		margin-right: 15px;
	}
	#frankenmenu-list .menu-item-depth1 > a {
		display: block;
		color: $color-secondary;
		padding: 8px 0;
		font-size: 1.2rem;
		text-transform: uppercase;
		font-weight: bold;
	}
	#frankenmenu-list .menu-item-depth1:nth-child(6) {
		margin-right: 0;
	}

	/* Hover */
	#frankenmenu-list .menu-item-depth1 > a:hover {
		color: $color-grey-05;
	}

	/* Current item */
	#frankenmenu-list .menu-item-depth1.menu-current-item > a {
		border-bottom: 4px solid $color-alternative;
	}

	/* ---- Submenus ---- */
	#frankenmenu-list .sub-menu a {
		border-top: 1px solid $color-accent-02;
		padding: 9px 18px 10px;
		color: #FFFFFF;
		font-size: 1.5rem;
		line-height: 1.8rem;
		background-color: lighten($color-accent-02, 10%);
		transition: all 200ms ease;
	}
	#frankenmenu-list .sub-menu .menu-item:first-child a {
		border-top: none;
	}
	#frankenmenu-list .sub-menu .menu-item > a:hover,
	#frankenmenu-list .sub-menu .menu-item > a:focus,
	#frankenmenu-list .sub-menu .menu-item.frankenhover > a,
	#frankenmenu-list .sub-menu .menu-item.frankenhold > a {
		background: $color-accent-02;
		color: #FFF;
	}

	#frankenmenu-list .frankenhold > .sub-menu {
		display: block !important;
		opacity: 1 !important;
	}

	/* ---- First level submenus ---- */
	#frankenmenu-list .sub-menu-depth1 {
		top: 100%;
		left: auto;
	}

	/* ---- Right aligned submenus ---- */
	#frankenmenu-list .sub-menu.submenu-right-align,
	#frankenmenu-list .sub-menu.submenu-right-align .sub-menu {
		left: auto;
		right: 100%;
	}
	#frankenmenu-list .sub-menu-depth1.submenu-right-align {
		left: auto;
		right: 0;
	}


	/* ---- Sub-navigation ---- */
	#frankenmenu-list .menu-item-subnav {
		display: none;
	}
	#frankenmenu-subnav {
		float: right;
	}
	#frankenmenu-subnav-list,
	#frankenmenu-subnav-list ul {
		list-style: none;
		padding: 0;
		margin: 0;
	}
	#frankenmenu-subnav-list .menu-item-depth1 {
		display: inline-block;
	}
	#frankenmenu-subnav-list .menu-item-depth1 > a {
		text-decoration: none;
		padding: 9px 4px;
		display: block;
		font-size: 1.3rem;
		font-weight: bold;
		text-transform: uppercase;
		margin-left: 12px;
	}
	#frankenmenu-subnav-list .menu-item-depth1 > a:hover {
		text-decoration: none;
	}
	#frankenmenu-subnav-list .sub-menu {
		display: none;
	}

	/* Home page item */
	#frankenmenu-subnav-list .menu-item-depth1.menu-home-page {
		text-indent: -9999px;
	}
	#frankenmenu-subnav-list .menu-item-depth1.menu-home-page > a {
		position: relative;
		width: 27px;
	}
	#frankenmenu-subnav-list .menu-item-depth1.menu-home-page > a:before {
		content: "\e908";
		font-family: 'icomoon' !important;
		speak: none;
		font-style: normal;
		font-weight: normal;
		font-variant: normal;
		text-transform: none;
		line-height: 1;
		font-size: 2.7rem;
		position: absolute;
		top: 4px;
		left: 0;
		right: 0;
		margin: 0 auto;
		text-indent: 0;
	}

	/* Current item */
	#frankenmenu-subnav-list .menu-item-depth1.menu-current-item > a {
		color: #FFF;
	}

	/* ---- Moved elements ---- */
	.frankenmoved {
		display: none;
	}
}


@include screen($bp992) {

	#frankenmenu-list {
		margin-top: 27px;
	}
	#frankenmenu-list .menu-item-depth1 {
		margin-right: 23px;
	}
	#frankenmenu-list .menu-item-depth1 > a {
		padding: 13px 0;
		font-size: 1.5rem;
	}
}

@include screen($bp1300) {
	#frankenmenu-list .menu-item-depth1 {
		margin-right: 34px;
	}
	#frankenmenu-list .menu-item-depth1 > a {
		padding: 17px 0;
		font-size: 1.6rem;
	}
	#frankenmenu-list {
		margin-top: 35px;
	}
}


/** Mobile menu only
===================================*/
@include screen($bp768, max) {
// @media all and (max-width: 768px) {

	/* Force iOS click events trigger anywhere */
	html {
		cursor: pointer;
	}

	#wrap {
		margin-top: 40px;
		transform: translate3d(0, 0, 0);
		-webkit-backface-visibility: hidden;
		backface-visibility: hidden;
		background-color: #FFF;
	}
	#frankenmenu {
		top: 0;
		position: fixed;
		overflow-y: scroll;
		overflow-x: hidden;
		height: 100%;
		background-color: $color-secondary;
		width: 70%;
		z-index: 0;
		transform: translate3d(0, 0, 0);
		-webkit-backface-visibility: hidden;
		backface-visibility: hidden;
		padding-top: 64px;
	}
	#frankenmenu .container {
		position: relative;
		padding: 0;
		max-width: none;
		width: 100%;
		margin: 0;
		opacity: 0;
	}

	/* ---- Mobile header ---- */
	#mobile-header {
		background-color: #ffffff;
		height: 40px;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		z-index: 100;
		box-shadow: 0 0 2px rgba(0,0,0,0.4);
	}
	#mobile-menu-button {
		-webkit-appearance: none;
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
		outline: none;
		border: none;
		padding: 0;
		text-indent: -9999px;
		width: 40px;
		height: 36px;
		margin-top: 2px;
		position: relative;
		background: none;
	}

	.mobile-menu-button-top,
	.mobile-menu-button-middle,
	.mobile-menu-button-bottom {
		height: 3px;
		background-color: $color-secondary;
		left: 6px;
		animation-fill-mode: forwards;
		animation-duration: 400ms;
		position: absolute;
		display: block;
		right: 6px;
	}
	.mobile-menu-button-top {
		top: 8px;
	}
	.mobile-menu-button-middle {
		top: 17px;
	}
	.mobile-menu-button-bottom {
		top: 26px;
	}

	/* Animations active */
	.frankenmenu-mob-menu-animations .mobile-menu-button-top {
		animation-name: mobileMenuBtnTopClose;
	}
	.frankenmenu-mob-menu-animations .mobile-menu-button-middle {
		animation-name: mobileMenuBtnMiddleClose;
	}
	.frankenmenu-mob-menu-animations .mobile-menu-button-bottom {
		animation-name: mobileMenuBtnBottomClose;
	}

	/* Close button */
	.frankenmenu-mob-menu-visible #mobile-menu-button .mobile-menu-button-top {
		animation-name: mobileMenuBtnTopOpen;
	}
	.frankenmenu-mob-menu-visible #mobile-menu-button .mobile-menu-button-middle {
		animation-name: mobileMenuBtnMiddleOpen;
	}
	.frankenmenu-mob-menu-visible #mobile-menu-button .mobile-menu-button-bottom {
		animation-name: mobileMenuBtnBottomOpen;
	}



	@keyframes mobileMenuBtnTopClose {
		0% {
			transform: translate(0, 9px) rotate(45deg);
		}
		50%, 70% {
			transform: translate(0, 9px);
		}
		100% {
			transform: translate(0, 0);
		}
	}
	@keyframes mobileMenuBtnTopOpen {
		0% {
			transform: translate(0, 0);
		}
		50%, 70% {
			transform: translate(0, 9px);
		}
		100% {
			transform: translate(0, 9px) rotate(45deg);
		}
	}
	@keyframes mobileMenuBtnBottomClose {
		0% {
			transform: translate(0, -9px) rotate(-45deg);
		}
		50%, 70% {
			transform: translate(0, -9px);
		}
		100% {
			transform: translate(0, 0);
		}
	}
	@keyframes mobileMenuBtnBottomOpen {
		0% {
			transform: translate(0, 0);
		}
		50%, 70% {
			transform: translate(0, -9px);
		}
		100% {
			transform: translate(0, -9px) rotate(-45deg);
		}
	}
	@keyframes mobileMenuBtnMiddleClose {
		0% {
			opacity: 0;
		}
		50%, 70%, 100% {
			opacity: 1;
		}
	}
	@keyframes mobileMenuBtnMiddleOpen {
		0% {
			opacity: 1;
		}
		50%, 70%, 100% {
			opacity: 0;
		}
	}

	/* ---- All level menu items ---- */
	#frankenmenu-list, #frankenmenu-list ul {
		width: 100%;
	}
	#frankenmenu-list .menu-item {
		position: relative;
		border-top: 1px solid #2d3332;
		float: none;
		width: 100%;
	}
	#frankenmenu-list a {
		min-height: 39px;
		width: 100%;
		padding: 9px 85px 9px 20px;
		color: #FFF;
	}
	#frankenmenu-list .menu-item.menu-current-item > a {
		background-color: #2d3332;
	}

	/* ---- First level menu ---- */
	#frankenmenu-list .menu-item-depth1:last-child {
		border-bottom: 1px solid #2d3332;
	}
	#frankenmenu-list .menu-item-depth1 > a {
		font-size: 1.5rem;
	}

	/* ---- Submenus ---- */
	#frankenmenu-list .sub-menu {
		display: none;
	}
	#frankenmenu-list .menu-current-item-ancestor > .sub-menu {
		display: block;
	}

	/* Depth differences */
	#frankenmenu-list .menu-item-depth1 > a {
		padding-left: 20px;
		background-color: $color-secondary;
	}
	#frankenmenu-list .menu-item-depth2 a {
		font-size: 1.4rem;
	}
	#frankenmenu-list .menu-item-depth2 > a  {
		padding-left: 20px;
		background-color: rgba(0,0,0,0.1);
	}
	#frankenmenu-list .menu-item-depth3 > a  {
		padding-left: 35px;
		background-color: rgba(0,0,0,0.2);
	}
	#frankenmenu-list .menu-item-depth4 > a  {
		padding-left: 50px;
		background-color: rgba(0,0,0,0.4);
	}
	#frankenmenu-list .menu-item-depth5 > a  {
		padding-left: 50px;
		background-color: rgba(0,0,0,0.5);
	}
	#frankenmenu-list .menu-item-depth6 > a  {
		padding-left: 50px;
		background-color: rgba(0,0,0,0.6);
	}

	/* Expand buttons */
	.submenu-toggle {
		-webkit-appearance: none;
		-moz-appearance: none;
		outline: none;
		border: none;
		color: #FFF;
		font-size: 2.3rem;
		background: #2d3332;
		width: 55px;
		height: 39px;
		display: block;
		padding: 0;
		margin: 0;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		cursor: pointer;
	}
	.submenu-toggle:before {
		content: " ";
		width: 21px;
		height: 12px;
		background: url(../images/icon-menu.svg) no-repeat 0 -30px;
		display: block;
		position: absolute;
		top: 50%;
		left: 50%;
		margin: 0 -50% 0 0;
		transform: translate(-50%, -50%);
		transform-origin: center center;
		transition: transform 300ms ease-in;
	}
	.menu-item-submenu-open > .submenu-toggle:before {
		transform: translate(-50%, -50%) rotate(180deg);
	}

	/* ---- Sub-navigation ---- */
	#frankenmenu-subnav-list {
		display: none;
	}

	/* ---- Menu positions ---- */

	/* Left */
	.frankenmenu-mobile-pos-left #frankenmenu {
		left: 0;
	}
	.frankenmenu-mobile-pos-left #frankenmenu .container {
		transform: translate3d(-10%, 0, 0) scale(0.9);
	}
	.frankenmenu-mobile-pos-left.frankenmenu-mob-menu-visible #wrap {
		transform: translate3d(70%, 0, 0);
	}

	/* Right */
	.frankenmenu-mobile-pos-right #frankenmenu {
		right: 0;
	}
	.frankenmenu-mobile-pos-right #frankenmenu .container {
		transform: translate3d(10%, 0, 0) scale(0.9);
	}
	.frankenmenu-mobile-pos-right.frankenmenu-mob-menu-visible #wrap {
		transform: translate3d(-70%, 0, 0);
	}
	.frankenmenu-mobile-pos-right #mobile-menu-button {
		float: right;
	}

	/* ---- Transitions ---- */
	#frankenmenu,
	#wrap,
	#frankenmenu .container {
		transition: all 350ms ease;
	}

	/* Menu open */
	.frankenmenu-mob-menu-visible #frankenmenu {
		transform: translate3d(0, 0, 0);
	}
	.frankenmenu-mob-menu-visible #frankenmenu .container {
		opacity: 1;
		transform: translate3d(0, 0, 0) scale(1);
	}

	/* ---- Moved elements ---- */
	.frankenmove {
		display: none;
	}

	/* ---- Megamenu ---- */
	.mega-menu-preview {
		display: none;
	}
	.mega-menu-preview-image {
		background-image: none !important;
	}
	.mega-menu-column-title {
		color: #FFF;
		font-size: 1.6rem;
		padding: 3px 20px;
		margin: 0;
		background: rgba(0,0,0,0.3);
	}
	#frankenmenu-list .mega-menu {
		display: none;
	}
	#frankenmenu-list .mega-menu ul {
		display: block;
	}

}
