

// Brand colours

$color-primary: 					#d3600e; // Orange

$color-secondary:                   #3d4442; // Dark grey

$color-alternative:                 #2c7edf; // mid blue

$color-accent-01: 				    #FD9F62; // light orange

$color-accent-02:                   #31373F; // Alt blue grey


// Neutral Colours

$color-white:						#ffffff;

$color-grey-01:                     #F7F6F6;

$color-grey-02:						#d8d8d8;

$color-grey-03:						#CACACA;

$color-grey-04:						#9FA09F;

$color-grey-05:						#777978;

// $color-grey-06:						#636363;

// $color-grey-07:						#474747;

$color-grey-08:						$color-secondary;

$color-grey-09:						#0E1211;

$color-black:						#000000;


// Utility Colours

$color-utility-positive:			#0E6F22;

$color-utility-positive-subtle:		#26883A;

$color-utility-alert:				#A72D00;

$color-utility-alert-subtle:		#CD4C1D;

$color-utility-warning:				#9E0E0B;

$color-utility-warning-subtle:		#C32C2A;

$color-utility-neutral:				#0192d0;

$color-utility-neutral-subtle:		#d3f2ff;

$color-utility-selection:           #FFC57D;

$color-utility-placeholder:         $color-grey-04;
