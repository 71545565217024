/*
Variable Example
*/
/*
Breakpoint Mixins
*/
:root {
  --body-font-size: 1.6rem;
  --h1-font-size: 2.5rem;
  --h1-line-height: inherit;
  --h2-font-size: 2rem;
  --h2-line-height: inherit;
  --h3-font-size: 1.8rem;
  --h3-line-height: inherit;
  --h4-font-size: 1.7rem;
  --h4-line-height: inherit;
  --h5-font-size: 1.6rem;
  --h5-line-height: inherit;
  --h6-font-size: 1.6rem;
  --h6-line-height: inherit;
  /* [02] */
  --giga-font-size: 8rem;
  --mega-font-size: 6.4rem;
  --kilo-font-size: 5.6rem;
  --milli-font-size: 1.2rem;
  --centi-font-size: 1.4rem;
}

@media screen and (min-width: 37.5em) {
  :root {
    --h1-font-size: 3rem;
    --h2-font-size: 2.2rem;
    --h3-font-size: 2.1rem;
    --h4-font-size: 1.8rem;
    --h5-font-size: 1.7rem;
  }
}
@media screen and (min-width: 62em) {
  :root {
    --h1-font-size: 3.3rem;
    --h2-font-size: 2.7rem;
  }
}
@media screen and (min-width: 81.25em) {
  :root {
    --h1-font-size: 3.7rem;
    --h2-font-size: 3.2rem;
    --h1-line-height: 1.3;
    --h2-line-height: 1.3;
    --h3-line-height: 1.3;
    --h4-line-height: 1.3;
    --h5-line-height: 1.3;
    --h6-line-height: 1.3;
  }
}
/*
Basic system font stacks
*/
/* Weights */
/* Size */
/* -----
* NOTES
* [02]
* A series of classes for setting massive type; for use in heroes, mastheads,
* promos, etc.
* As per: csswizardry.com/2012/02/pragmatic-practical-font-sizing-in-css
------ */
/* Fade in */
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Fade in down */
@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
}
@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
}
/* Fade in up */
@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
/* Fade in up margin */
@-webkit-keyframes fadeInUpMargin {
  from {
    opacity: 0;
    margin-bottom: -20px;
  }
  to {
    opacity: 1;
    margin-bottom: 0;
  }
}
@keyframes fadeInUpMargin {
  from {
    opacity: 0;
    margin-bottom: -20px;
  }
  to {
    opacity: 1;
    margin-bottom: 0;
  }
}
/* Grow in fade */
@-webkit-keyframes growInFade {
  from {
    opacity: 0;
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes growInFade {
  from {
    opacity: 0;
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
/* ---- Highlight pulse ---- */
@-webkit-keyframes highlightPulse {
  40% {
    -webkit-transform: scale(1.25);
    transform: scale(1.25);
  }
}
@keyframes highlightPulse {
  40% {
    -webkit-transform: scale(1.25);
    transform: scale(1.25);
  }
}
/* ---- Highlight pulse with outline ---- */
@-webkit-keyframes highlightPulseOutline {
  0% {
    outline: 3px solid #FFC57D;
    outline-offset: 4px;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  13% {
    -webkit-transform: scale(1.25);
    transform: scale(1.25);
  }
  33% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  75% {
    outline: 3px solid #FFC57D;
    outline-offset: 4px;
  }
  100% {
    outline: 3px solid transparent;
    outline-offset: 4px;
  }
}
@keyframes highlightPulseOutline {
  0% {
    outline: 3px solid #FFC57D;
    outline-offset: 4px;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  13% {
    -webkit-transform: scale(1.25);
    transform: scale(1.25);
  }
  33% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  75% {
    outline: 3px solid #FFC57D;
    outline-offset: 4px;
  }
  100% {
    outline: 3px solid transparent;
    outline-offset: 4px;
  }
}
/* ---- Ken burns ---- */
@-webkit-keyframes kenBurns {
  50% {
    -webkit-transform: scale(1.25);
    transform: scale(1.25);
  }
}
@keyframes kenBurns {
  50% {
    -webkit-transform: scale(1.25);
    transform: scale(1.25);
  }
}
.animate-fade {
  animation: fadeInDown 400ms ease-in-out;
}

.demo-animate .demo-animate__item {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.demo-animate:hover .demo-animate__item {
  clip: auto;
  height: auto;
  overflow: visible;
  position: relative;
  width: auto;
  padding: 5px;
  -webkit-animation: fadeInDown 400ms ease-in-out;
  animation: fadeInDown 400ms ease-in-out;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

/*
 * frankenMenu v1.0 // Karmabunny Web Design // built by Luke Underwood
 */
/** Desktop and Mobile styling
===================================*/
#frankenmenu-list,
#frankenmenu-list ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: block;
}

#frankenmenu-list a {
  text-decoration: none;
  display: block;
  font-size: 1.6rem;
}

/** Desktop menu only
===================================*/
@media screen and (min-width: 48.0625em) {
  #frankenmenu {
    z-index: 99999;
  }

  #frankenmenu-list {
    text-align: left;
    float: right;
    margin-top: 28px;
  }

  #mobile-header {
    display: none;
  }

  /* ---- All level menu items ---- */
  #frankenmenu-list .menu-item {
    position: relative;
  }

  #frankenmenu-list .sub-menu {
    display: none;
    min-width: 200px;
    position: absolute;
    box-shadow: 0 0 16px 1px rgba(0, 0, 0, 0.4);
    z-index: 1;
    min-width: 250px;
    max-width: 350px;
    text-align: left;
    top: 0;
    left: 100%;
  }

  /* ---- First level menu ---- */
  #frankenmenu-list .menu-item-depth1 {
    float: left;
    text-align: center;
    margin-right: 15px;
  }

  #frankenmenu-list .menu-item-depth1 > a {
    display: block;
    color: #3d4442;
    padding: 8px 0;
    font-size: 1.2rem;
    text-transform: uppercase;
    font-weight: bold;
  }

  #frankenmenu-list .menu-item-depth1:nth-child(6) {
    margin-right: 0;
  }

  /* Hover */
  #frankenmenu-list .menu-item-depth1 > a:hover {
    color: #777978;
  }

  /* Current item */
  #frankenmenu-list .menu-item-depth1.menu-current-item > a {
    border-bottom: 4px solid #2c7edf;
  }

  /* ---- Submenus ---- */
  #frankenmenu-list .sub-menu a {
    border-top: 1px solid #31373F;
    padding: 9px 18px 10px;
    color: #FFFFFF;
    font-size: 1.5rem;
    line-height: 1.8rem;
    background-color: #47505c;
    transition: all 200ms ease;
  }

  #frankenmenu-list .sub-menu .menu-item:first-child a {
    border-top: none;
  }

  #frankenmenu-list .sub-menu .menu-item > a:hover,
#frankenmenu-list .sub-menu .menu-item > a:focus,
#frankenmenu-list .sub-menu .menu-item.frankenhover > a,
#frankenmenu-list .sub-menu .menu-item.frankenhold > a {
    background: #31373F;
    color: #FFF;
  }

  #frankenmenu-list .frankenhold > .sub-menu {
    display: block !important;
    opacity: 1 !important;
  }

  /* ---- First level submenus ---- */
  #frankenmenu-list .sub-menu-depth1 {
    top: 100%;
    left: auto;
  }

  /* ---- Right aligned submenus ---- */
  #frankenmenu-list .sub-menu.submenu-right-align,
#frankenmenu-list .sub-menu.submenu-right-align .sub-menu {
    left: auto;
    right: 100%;
  }

  #frankenmenu-list .sub-menu-depth1.submenu-right-align {
    left: auto;
    right: 0;
  }

  /* ---- Sub-navigation ---- */
  #frankenmenu-list .menu-item-subnav {
    display: none;
  }

  #frankenmenu-subnav {
    float: right;
  }

  #frankenmenu-subnav-list,
#frankenmenu-subnav-list ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  #frankenmenu-subnav-list .menu-item-depth1 {
    display: inline-block;
  }

  #frankenmenu-subnav-list .menu-item-depth1 > a {
    text-decoration: none;
    padding: 9px 4px;
    display: block;
    font-size: 1.3rem;
    font-weight: bold;
    text-transform: uppercase;
    margin-left: 12px;
  }

  #frankenmenu-subnav-list .menu-item-depth1 > a:hover {
    text-decoration: none;
  }

  #frankenmenu-subnav-list .sub-menu {
    display: none;
  }

  /* Home page item */
  #frankenmenu-subnav-list .menu-item-depth1.menu-home-page {
    text-indent: -9999px;
  }

  #frankenmenu-subnav-list .menu-item-depth1.menu-home-page > a {
    position: relative;
    width: 27px;
  }

  #frankenmenu-subnav-list .menu-item-depth1.menu-home-page > a:before {
    content: "\e908";
    font-family: "icomoon" !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    font-size: 2.7rem;
    position: absolute;
    top: 4px;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-indent: 0;
  }

  /* Current item */
  #frankenmenu-subnav-list .menu-item-depth1.menu-current-item > a {
    color: #FFF;
  }

  /* ---- Moved elements ---- */
  .frankenmoved {
    display: none;
  }
}
@media screen and (min-width: 62em) {
  #frankenmenu-list {
    margin-top: 27px;
  }

  #frankenmenu-list .menu-item-depth1 {
    margin-right: 23px;
  }

  #frankenmenu-list .menu-item-depth1 > a {
    padding: 13px 0;
    font-size: 1.5rem;
  }
}
@media screen and (min-width: 81.25em) {
  #frankenmenu-list .menu-item-depth1 {
    margin-right: 34px;
  }

  #frankenmenu-list .menu-item-depth1 > a {
    padding: 17px 0;
    font-size: 1.6rem;
  }

  #frankenmenu-list {
    margin-top: 35px;
  }
}
/** Mobile menu only
===================================*/
@media screen and (max-width: 48em) {
  /* Force iOS click events trigger anywhere */
  html {
    cursor: pointer;
  }

  #wrap {
    margin-top: 40px;
    transform: translate3d(0, 0, 0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background-color: #FFF;
  }

  #frankenmenu {
    top: 0;
    position: fixed;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100%;
    background-color: #3d4442;
    width: 70%;
    z-index: 0;
    transform: translate3d(0, 0, 0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    padding-top: 64px;
  }

  #frankenmenu .container {
    position: relative;
    padding: 0;
    max-width: none;
    width: 100%;
    margin: 0;
    opacity: 0;
  }

  /* ---- Mobile header ---- */
  #mobile-header {
    background-color: #ffffff;
    height: 40px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.4);
  }

  #mobile-menu-button {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    outline: none;
    border: none;
    padding: 0;
    text-indent: -9999px;
    width: 40px;
    height: 36px;
    margin-top: 2px;
    position: relative;
    background: none;
  }

  .mobile-menu-button-top,
.mobile-menu-button-middle,
.mobile-menu-button-bottom {
    height: 3px;
    background-color: #3d4442;
    left: 6px;
    animation-fill-mode: forwards;
    animation-duration: 400ms;
    position: absolute;
    display: block;
    right: 6px;
  }

  .mobile-menu-button-top {
    top: 8px;
  }

  .mobile-menu-button-middle {
    top: 17px;
  }

  .mobile-menu-button-bottom {
    top: 26px;
  }

  /* Animations active */
  .frankenmenu-mob-menu-animations .mobile-menu-button-top {
    animation-name: mobileMenuBtnTopClose;
  }

  .frankenmenu-mob-menu-animations .mobile-menu-button-middle {
    animation-name: mobileMenuBtnMiddleClose;
  }

  .frankenmenu-mob-menu-animations .mobile-menu-button-bottom {
    animation-name: mobileMenuBtnBottomClose;
  }

  /* Close button */
  .frankenmenu-mob-menu-visible #mobile-menu-button .mobile-menu-button-top {
    animation-name: mobileMenuBtnTopOpen;
  }

  .frankenmenu-mob-menu-visible #mobile-menu-button .mobile-menu-button-middle {
    animation-name: mobileMenuBtnMiddleOpen;
  }

  .frankenmenu-mob-menu-visible #mobile-menu-button .mobile-menu-button-bottom {
    animation-name: mobileMenuBtnBottomOpen;
  }

  @keyframes mobileMenuBtnTopClose {
    0% {
      transform: translate(0, 9px) rotate(45deg);
    }
    50%, 70% {
      transform: translate(0, 9px);
    }
    100% {
      transform: translate(0, 0);
    }
  }
  @keyframes mobileMenuBtnTopOpen {
    0% {
      transform: translate(0, 0);
    }
    50%, 70% {
      transform: translate(0, 9px);
    }
    100% {
      transform: translate(0, 9px) rotate(45deg);
    }
  }
  @keyframes mobileMenuBtnBottomClose {
    0% {
      transform: translate(0, -9px) rotate(-45deg);
    }
    50%, 70% {
      transform: translate(0, -9px);
    }
    100% {
      transform: translate(0, 0);
    }
  }
  @keyframes mobileMenuBtnBottomOpen {
    0% {
      transform: translate(0, 0);
    }
    50%, 70% {
      transform: translate(0, -9px);
    }
    100% {
      transform: translate(0, -9px) rotate(-45deg);
    }
  }
  @keyframes mobileMenuBtnMiddleClose {
    0% {
      opacity: 0;
    }
    50%, 70%, 100% {
      opacity: 1;
    }
  }
  @keyframes mobileMenuBtnMiddleOpen {
    0% {
      opacity: 1;
    }
    50%, 70%, 100% {
      opacity: 0;
    }
  }
  /* ---- All level menu items ---- */
  #frankenmenu-list, #frankenmenu-list ul {
    width: 100%;
  }

  #frankenmenu-list .menu-item {
    position: relative;
    border-top: 1px solid #2d3332;
    float: none;
    width: 100%;
  }

  #frankenmenu-list a {
    min-height: 39px;
    width: 100%;
    padding: 9px 85px 9px 20px;
    color: #FFF;
  }

  #frankenmenu-list .menu-item.menu-current-item > a {
    background-color: #2d3332;
  }

  /* ---- First level menu ---- */
  #frankenmenu-list .menu-item-depth1:last-child {
    border-bottom: 1px solid #2d3332;
  }

  #frankenmenu-list .menu-item-depth1 > a {
    font-size: 1.5rem;
  }

  /* ---- Submenus ---- */
  #frankenmenu-list .sub-menu {
    display: none;
  }

  #frankenmenu-list .menu-current-item-ancestor > .sub-menu {
    display: block;
  }

  /* Depth differences */
  #frankenmenu-list .menu-item-depth1 > a {
    padding-left: 20px;
    background-color: #3d4442;
  }

  #frankenmenu-list .menu-item-depth2 a {
    font-size: 1.4rem;
  }

  #frankenmenu-list .menu-item-depth2 > a {
    padding-left: 20px;
    background-color: rgba(0, 0, 0, 0.1);
  }

  #frankenmenu-list .menu-item-depth3 > a {
    padding-left: 35px;
    background-color: rgba(0, 0, 0, 0.2);
  }

  #frankenmenu-list .menu-item-depth4 > a {
    padding-left: 50px;
    background-color: rgba(0, 0, 0, 0.4);
  }

  #frankenmenu-list .menu-item-depth5 > a {
    padding-left: 50px;
    background-color: rgba(0, 0, 0, 0.5);
  }

  #frankenmenu-list .menu-item-depth6 > a {
    padding-left: 50px;
    background-color: rgba(0, 0, 0, 0.6);
  }

  /* Expand buttons */
  .submenu-toggle {
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;
    border: none;
    color: #FFF;
    font-size: 2.3rem;
    background: #2d3332;
    width: 55px;
    height: 39px;
    display: block;
    padding: 0;
    margin: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
  }

  .submenu-toggle:before {
    content: " ";
    width: 21px;
    height: 12px;
    background: url(../images/icon-menu.svg) no-repeat 0 -30px;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: 0 -50% 0 0;
    transform: translate(-50%, -50%);
    transform-origin: center center;
    transition: transform 300ms ease-in;
  }

  .menu-item-submenu-open > .submenu-toggle:before {
    transform: translate(-50%, -50%) rotate(180deg);
  }

  /* ---- Sub-navigation ---- */
  #frankenmenu-subnav-list {
    display: none;
  }

  /* ---- Menu positions ---- */
  /* Left */
  .frankenmenu-mobile-pos-left #frankenmenu {
    left: 0;
  }

  .frankenmenu-mobile-pos-left #frankenmenu .container {
    transform: translate3d(-10%, 0, 0) scale(0.9);
  }

  .frankenmenu-mobile-pos-left.frankenmenu-mob-menu-visible #wrap {
    transform: translate3d(70%, 0, 0);
  }

  /* Right */
  .frankenmenu-mobile-pos-right #frankenmenu {
    right: 0;
  }

  .frankenmenu-mobile-pos-right #frankenmenu .container {
    transform: translate3d(10%, 0, 0) scale(0.9);
  }

  .frankenmenu-mobile-pos-right.frankenmenu-mob-menu-visible #wrap {
    transform: translate3d(-70%, 0, 0);
  }

  .frankenmenu-mobile-pos-right #mobile-menu-button {
    float: right;
  }

  /* ---- Transitions ---- */
  #frankenmenu,
#wrap,
#frankenmenu .container {
    transition: all 350ms ease;
  }

  /* Menu open */
  .frankenmenu-mob-menu-visible #frankenmenu {
    transform: translate3d(0, 0, 0);
  }

  .frankenmenu-mob-menu-visible #frankenmenu .container {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(1);
  }

  /* ---- Moved elements ---- */
  .frankenmove {
    display: none;
  }

  /* ---- Megamenu ---- */
  .mega-menu-preview {
    display: none;
  }

  .mega-menu-preview-image {
    background-image: none !important;
  }

  .mega-menu-column-title {
    color: #FFF;
    font-size: 1.6rem;
    padding: 3px 20px;
    margin: 0;
    background: rgba(0, 0, 0, 0.3);
  }

  #frankenmenu-list .mega-menu {
    display: none;
  }

  #frankenmenu-list .mega-menu ul {
    display: block;
  }
}